.file {
    margin-bottom: 10px;
    margin-Top: 10px;
}

.filesname {
    max-Width: 200px;
    min-width: 200px;
    word-Wrap: break-word;
}

.button {
    margin: 2px;
    padding: 2px 5px;
    border-radius: 5px;
    color: crimson;
    border: 1px dotted rgba(220, 20, 60, 0.6);
    cursor: pointer;
}