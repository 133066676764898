.datatable {
  height: 85%;
  padding: 20px;

  .datatableTitle {
    width: 100%;
    font-size: 24px;
    color: gray;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .link {
      text-decoration: none;
      color: green;
      font-size: 16px;
      font-weight: 400;
      border: 1px solid green;
      padding: 5px;
      border-radius: 5px;
      cursor: pointer;
    }
  }

  .cellWithImg {
    display: flex;
    align-items: center;

    .cellImg {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 20px;
    }
  }

  .cellWithStatus {
    padding: 5px;
    border-radius: 5px;

    &.active {
      background-color: rgba(0, 128, 0, 0.05);
      color: green;
    }

    &.pending {
      background-color: rgba(255, 217, 0, 0.05);
      color: goldenrod;
    }

    &.passive {
      background-color: rgba(255, 0, 0, 0.05);
      color: crimson;
    }
  }

  .cellAction {
    display: flex;
    align-items: center;
    gap: 15px;

    .viewButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: darkblue;
      border: 1px dotted rgba(0, 0, 139, 0.596);
      cursor: pointer;
    }

    .deleteButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: crimson;
      border: 1px dotted rgba(220, 20, 60, 0.6);
      cursor: pointer;
    }

    .updateButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: crimson;
      border: 1px dotted rgba(220, 20, 60, 0.6);
      cursor: pointer;
    }

  }
}

.updateButton {
  background-color: rgb(226 47 86);
  border-radius: 7px;
  color: #ffff;
  cursor: pointer;
  height: 4.5vh;
  margin: 2px;
  font-size: 25px;
}

.buttonspace {
  display: flex;
  direction: ltr;
  justify-content: start;
  margin-right: 4px;
}

.container {
  overflow-x: auto;
  margin-right: auto;
  margin-left: auto;
  margin-top: 50px;
  padding: 10px;
  margin: 10px;
}

.title {
  margin-top: 20px;
  text-align: center;
}

tr {
  height: 40px !important;
}

th {
  min-width: 125px;
  padding: 0 !important;
  overflow-x: auto;
  text-align: center !important;
}

td {
  height: 40px !important;
  padding: 5px !important;
  text-align: center !important;
}

// td {
//   /* set background color */
//   // background-color: #f2f2f2;
//   text-align: right;
//   /* set text color */
//   color: #333;

//   /* set font size */
//   font-size: 14px;

//   /* set font family */
//   font-family: Arial, sans-serif;

//   /* set border */
//   border: 1px solid #ddd;

//   /* set padding */
//   padding: 10px;

//   /* set text alignment */
//   text-align: center;

//   /* set vertical alignment */
//   vertical-align: middle;
// }

// .tr {
//   background-color: #f2f2f2;
//   line-height: 1.43;
//   letter-spacing: 0.01071em;
//   font-size:18px;
//   /* set font family */
//   font-family: 'Nunito, sans-serif';

//   /* set border */
//   border: 1px solid #ddd;

//   /* set padding */
//   padding: 10px;


//   /* set vertical alignment */
//   vertical-align: middle;

//   /* set hover effect */
//   transition: all 0.2s ease;
//   direction: rtl;
// }

